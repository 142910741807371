import React from 'react';

const ThanksPage = () => {
  return (
   <div>
       <h2>Thanks</h2>
   </div>
  )
};

export default ThanksPage;